import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';
import Box from '@lce/slice_v2/Layout/Box';
import Text from '@lce/slice_v2/Elements/Text';
import Image from '@lce/slice_v2/Elements/Image';
import Heading from '@lce/slice_v2/Elements/Heading';
import Script from '@lce/intl-ui/src/Elements/Script';

import BBQChickenPizza from './assets/bbq-chk-o-rshadow-cmyk-sing.png';
import Prints from './assets/chicken-prints.png';
import CurrencySpearBlack from './assets/currency_spear_symbol_black.png';
import * as Styled from './BBQChickenLto.style';

const BBQChickenLto = () => (
  <Flex sx={Styled.BBQChicken}>
    <Flex sx={Styled.Pizza}>
      <Image alt="" src={BBQChickenPizza} sx={Styled.Image}/>
    </Flex>
    <Flex sx={Styled.Content}>
      <Image alt="" src={Prints} sx={Styled.Prints} />
      <Heading as="h1" sx={Styled.LtoText}>
        <Script sx={Styled.Script}>New!</Script>
        BBQ Chicken
      </Heading>
      <Box sx={Styled.Description}>
        <Text as="span" sx={{ display: ['inline', 'block'] }}>
          Topped with our signature <strong>BBQ Ranch Sauce </strong>
        </Text>
        <Text as="span" sx={{ display: ['inline', 'block'] }}>
          a blend of <strong>mozzarella</strong> and <strong>muenster cheeses</strong>,{' '}
        </Text>
        <Text as="span" sx={{ display: ['inline', 'block'] }}>
          marinated <strong>chicken</strong> and <strong>onions</strong>!
        </Text>
      </Box>
      <Heading as="h2" sx={Styled.LtoPriceText}>
        <Image src={ CurrencySpearBlack } sx={Styled.CurrencySymbol} />
        13<Text as="span" sx={Styled.LtoCents}>49</Text>
      </Heading>
    </Flex>
  </Flex>
);

export default BBQChickenLto;
