import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';
import Image from '@lce/slice_v2/Elements/Image';
import { map as _map } from 'lodash';

import useLeavingDialog from '@lce/gatsby-theme-international/src/api/LeavingDialog/useLeavingDialog';
import LeavingDialog from '@lce/intl-ui/src/Patterns/Modals/LeavingDialog/LeavingDialog';
import BannerImage from './assets/HiringBannerImg.jpg';

const HiringBanner = () => {
  const dialogs = useLeavingDialog('en-SG');
  const [isOpen, setOpen] = React.useState(false);
  let leavingToHiringDialog;

  _map(dialogs, (dialog) => {
    if (dialog.modalName === 'NonDeliveryLeavingDialog') {
      leavingToHiringDialog = dialog;
    }
  });

  return (
    <>
      <Flex data-testid='HiringBannerCus' onClick={() => setOpen(true)} sx={{ height: '500px' }}>
        <Image alt="" src={BannerImage} sx={{ margin: 'auto' }} />
      </Flex>
      {leavingToHiringDialog && (
        <LeavingDialog
          dialog={leavingToHiringDialog}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default HiringBanner;
