import * as React from 'react';
//Import custom banners here and add them to the Banners object.
import BBQChickenLto from './BBQChickenLto';
import StuffedCrust from './StuffedCrustBanner';
import HiringBanner from './HiringBanner';

export interface IObjectIndexer<T> {
  [key: string]: T;
}

const Banners: IObjectIndexer<React.FC<any>> = {
  BBQChickenLto,
  StuffedCrust,
  HiringBanner,
};

export default Banners;
