import { SxStyleProp } from 'theme-ui';

export const BBQChicken: SxStyleProp = {
  flexDirection: ['column-reverse', 'row'],
  alignItems: 'stretch',
  minHeight: '424px',
  backgroundColor: 'primary',
};

export const Pizza: SxStyleProp = {
  width: ['100%', 'calc(100% - 380px)', 'calc(100% - 420px)'],
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  minHeight: ['auto', '424px'],
};

export const Image: SxStyleProp = {
  width: '100%',
  height: 'auto',
  minHeight: '1px',
  verticalAlign: 0,
  m: 0,
};

export const Content: SxStyleProp = {
  position: 'relative',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: ['268px', '380px', '420px'],
  alignSelf: ['center', 'center', 'inherit'],
  minHeight: ['auto', '424px', 'auto'],
};

export const Prints: SxStyleProp = {
  verticalAlign: 'middle',
  position: 'absolute',
  display: ['none', 'none', 'block'],
  bottom: 0,
  right: 0,
  margin: 0,
};

export const LtoText: SxStyleProp = {
  lineHeight: '0.8em',
  fontSize: ['52px', '68px', '76px'],
  margin: '28px 0 0 0',
};

export const Script: SxStyleProp = {
  color: 'black',
  fontSize: '0.55em',
  m: '0 0 -8px -8px',
  display: 'block',
};

export const Description: SxStyleProp = {
  fontFamily: 'primary',
  color: 'white',
  fontWeight: 'normal !important',
  flexDirection: 'column',
  mt: 3,
  mb: 0,
  fontSize: [10, 12],
  lineHeight: ['20px', '24px'],
  textTransform: 'inherit',
  display: 'initial',
};

export const LtoPriceText: SxStyleProp = {
  color: 'black',
  display: 'flex',
  justifyContent: 'flex-start',
  lineHeight: '0.8em',
  fontSize: [64, 90],
  margin: 0,
};

export const CurrencySymbol: SxStyleProp = {
  width: ['22px', '32px'],
  height: ['67.66px', '98.39px'],
  alignSelf: 'flex-start',
  mt: 3,
};

export const LtoCents: SxStyleProp = {
  display: 'inline',
  fontSize: '0.6em',
};
