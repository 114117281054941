import { SxStyleProp } from 'theme-ui';

export const StuffedCrustBanner: SxStyleProp = {
  flexDirection: ['column-reverse', 'row'],
  alignItems: 'stretch',
  minHeight: '424px',
  backgroundColor: 'primary',
};

export const Pizza: SxStyleProp = {
  width: ['100%', 'calc(100% - 340px)', 'calc(100% - 420px)'],
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  mb: '28px',
  minHeight: ['auto', 'auto', '424px'],
};

export const Image: SxStyleProp = {
  width: '100%',
  height: 'auto',
  minHeight: '1px',
  verticalAlign: 'middle',
};

export const Content: SxStyleProp = {
  position: 'relative',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: ['268px', '340px', '420px'],
  alignSelf: ['center', 'center', 'inherit'],
  minHeight: ['auto', '424px', 'auto'],
};

export const BannerTitle: SxStyleProp = {
  color: 'white',
  lineHeight: '0.8em',
  fontSize: [26, 34, 38],
  margin: ['28px 0 0 0', '28px 0 0 0', '28px 0 20px 0'],
};

export const Script: SxStyleProp = {
  color: 'black',
  fontSize: '0.55em',
  m: '0 0 -8px -8px',
  display: 'block',
};

export const CurrencySymbol: SxStyleProp = {
  width: ['22px', '32px'],
  height: ['67.66px', '98.39px'],
  alignSelf: 'flex-start',
  mt: 0,
};


export const PriceText: SxStyleProp = {
  display: 'flex',
  justifyContent: 'flex-start',
  lineHeight: ['0.8em', '120px'],
  fontSize: [64, 90],
  m: 0,
};

export const Cents: SxStyleProp = {
  display: 'inline-block',
  lineHeight: '56px',
  fontWeight: 'bold',
  fontSize: '0.4em',
};

export const BannerDescription: SxStyleProp = {
  color: 'black',
  fontFamily: 'primary',
  fontWeight: 'bold',
  fontSize: [12, 14],
  display: 'initial',
  lineHeight: ['32px', '28px'],
};
