import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';
import Box from '@lce/slice_v2/Layout/Box';
import Image from '@lce/slice_v2/Elements/Image';
import Heading from '@lce/slice_v2/Elements/Heading';
import Text from '@lce/slice_v2/Elements/Text';
import Script from '@lce/intl-ui/src/Elements/Script';

import StuffedCrustPizza from './assets/Singapore_HomePageBanner_StuffedCrust_ImageOnly.png';
import CurrencySpearBlack from './assets/currency_spear_symbol_black.png';
import * as Styled from './StuffedCrust.style';

const StuffedCrust = () => (
  <Flex sx={Styled.StuffedCrustBanner}>
    <Flex sx={Styled.Pizza}>
      <Image alt="" src={StuffedCrustPizza} sx={Styled.Image} />
    </Flex>
    <Flex sx={Styled.Content}>
      <Heading as="h1" sx={Styled.BannerTitle}>
        <Script sx={Styled.Script}>Make it</Script>
        STUFFED CRUST
      </Heading>
      <Flex sx={{ alignItems: 'flex-start' }}>
        <Image src={CurrencySpearBlack} sx={Styled.CurrencySymbol} />
        <Heading as="h2" sx={Styled.PriceText}>
          4<Text as="span" sx={Styled.Cents}>50</Text>
        </Heading>
        <Box sx={Styled.BannerDescription}>MORE</Box>
      </Flex>
      <Box sx={Styled.BannerDescription}>ADD TO ANY PIZZA</Box>
    </Flex>
  </Flex>
);

export default StuffedCrust;
